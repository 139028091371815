import { UserClaims } from "@okta/okta-auth-js/lib/types/UserClaims";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEmployeeNumber } from "../../../authentication/currentUser/employeeNumber";
import { oktaClient } from "../../../authentication/oktaClient";

type oktaUserState = {
  data?: UserClaims;
};

const initialOktaUserState = {
  data: null,
};

export const oktaUserSlice = createSlice({
  name: "oktaUser",
  initialState: initialOktaUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchOktaUserThunk.fulfilled,
      (state, action: PayloadAction<UserClaims>) => {
        state.data = action.payload;
      }
    );
  },
});

export const fetchOktaUserThunk = createAsyncThunk(
  "fetchOktaUser",
  async (_, _thunkAPI) => {
    const employeeNumber = await getEmployeeNumber();
    return oktaClient.getUser().then((claims) => {
      return { ...claims, employeeNumber, emp: employeeNumber };
    });
  }
);
