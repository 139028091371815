export function introSubRoute() {
  return {
    type: "div",
    routes: [
      {
        type: "application",
        name: "@mytw/intro",
      },
    ],
  };
}
