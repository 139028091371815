import { configureStore } from "@reduxjs/toolkit";
import { applicationConfigSlice } from "./applicationConfiguration";

export function constructStore() {
  return configureStore({
    reducer: applicationConfigSlice.reducer,
  });
}

export const applicationStore = constructStore();
