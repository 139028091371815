import { createTheme } from "@mui/material/styles";

/**
 * https://mui.com/material-ui/customization/theming/
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#634F7D",
      dark: "#4A3B5E",
      light: "#B0A1C4",
    },
    secondary: {
      main: "#BD4257",
      dark: "#9B293C",
      light: "#F2617A",
    },
    background: {
      default: "#EDF1F3",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#616364",
      disabled: "rgba(0,0,0,0.37)",
    },
    action: {
      focus: "#9B293C",
    },
  },
  typography: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeightLight: 300,
    h1: {
      fontFamily: "Bitter",
      fontSize: 34,
      fontWeight: 700,
      lineHeight: 1.06,
      letterSpacing: 0.2,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: 0.18,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: 0.15,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.5,
      color: "#6C3DA6",
    },
    h5: {
      fontSize: 14,
      lineHeight: 2.1,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    button: {
      fontSize: 14,
      lineHeight: 1.15,
      textTransform: "none",
    },
    overline: {
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 20,
          fontWeight: 600,
          lineHeight: 1.3,
          letterSpacing: 0.15,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: "none",
          bgColor: "#fff",
        },
      },
    },
  },
});

export default theme;
