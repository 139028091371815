import { getOauthClientId, getOauthIssuer } from "../../configurations/globals";
import { OktaAuth } from "@okta/okta-auth-js";

const oktaConfig = {
  issuer: getOauthIssuer(),
  clientId: getOauthClientId(),
  redirectUri: window.location.origin,
  scope: ["employeeNumber", "openid", "profile", "email", "address", "phone"],
};
export const oktaClient = new OktaAuth(oktaConfig);
